@font-face {
    font-family: 'glyphicons-theme';
    src:  url('./fonts/glyphicons/glyphicons-theme.eot');
    src:  url('./fonts/glyphicons/glyphicons-theme.eot') format('embedded-opentype'),
    url('./fonts/glyphicons/glyphicons-theme.ttf') format('truetype'),
    url('./fonts/glyphicons/glyphicons-theme.woff') format('woff'),
    url('./fonts/glyphicons/glyphicons-theme.svg') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="theme-icon-"], [class*=" theme-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'glyphicons-theme' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.theme-icon-alarm:before {
    content: "\e900";
}
.theme-icon-bigcircle:before {
    content: "\e901";
}
.theme-icon-cadr:before {
    content: "\e902";
}
.theme-icon-close1:before {
    content: "\e903";
}
.theme-icon-close2:before {
    content: "\e904";
}
.theme-icon-date:before {
    content: "\e905";
}
.theme-icon-done:before {
    content: "\e906";
}
.theme-icon-edit:before {
    content: "\e907";
}
.theme-icon-leaf-edit:before {
    content: "\e908";
}
.theme-icon-error:before {
    content: "\e909";
}
.theme-icon-menu:before {
    content: "\e90a";
}
.theme-icon-quote:before {
    content: "\e90b";
}
.theme-icon-sand:before {
    content: "\e90c";
}
.theme-icon-search:before {
    content: "\e90d";
}
.theme-icon-select1:before {
    content: "\e90e";
}
.theme-icon-select2:before {
    content: "\e90f";
}
.theme-icon-textarea:before {
    content: "\e910";
}
.theme-icon-trash:before {
    content: "\e911";
}
.theme-icon-upload:before {
    content: "\e912";
}
.theme-icon-save:before {
    content: "\e913";
}
